.loading-container{
    position: sticky;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loading-text{
    font-size: 3rem;
    color: #006970;
    margin: 0.25em 0rem;
    opacity: 0;
    animation: appear 2s 2s forwards, pulse 2s 4s infinite alternate-reverse;
}

@keyframes appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }
  
@keyframes pulse {
    0% {
        opacity: 0.35;
    }
    100% {
        opacity: 1;
    }
  }